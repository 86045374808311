import './App.css';
import logo from './assets/images/company-logo.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <p>
          ATD Team Limited
        </p> */}
      </header>
    </div>
  );
}

export default App;
